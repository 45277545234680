import React from 'react'
import { Modal } from 'antd'
import { ButtonCancel, ButtonSave } from 'components/buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from "@fortawesome/pro-duotone-svg-icons"
import './index.scss'


export default function Dialog({
  visible = false, // boolean
  className, // string
  title, // string
  buttonClose, // boolean or ReactNode
  buttonCancel, // boolean or ReactNode
  onCancel, // function
  buttonOk, // boolean or ReactNode
  onOk, // function
  children // ReactNode
}) {

  const closeIcon = React.useMemo(() => (
    React.isValidElement(buttonClose) ? buttonClose :
      buttonClose && typeof onCancel === 'function' && <FontAwesomeIcon icon={faTimes} className="dialog-close" title="Close" />
  ), [buttonClose, onCancel])


  const footer = React.useMemo(() => (
    <div className='dialog-footer'>
      {React.isValidElement(buttonCancel) ? buttonCancel :
        buttonCancel && typeof onCancel === 'function' && <ButtonCancel onClick={onCancel} />}
      {React.isValidElement(buttonOk) ? buttonOk :
        buttonOk && typeof onOk === 'function' && <ButtonSave onClick={onOk} />}
    </div>
  ), [buttonCancel, onCancel, buttonOk, onOk])

  return (
    <Modal
      className={className}
      visible={visible}
      wrapClassName="dialog"
      maskClosable={false}
      // Header
      title={<div className="dialog-title">{title}</div>}
      closable={closeIcon && true}
      closeIcon={closeIcon}
      // Footer
      onCancel={onCancel}
      onOk={onOk}
      footer={footer}
    >{children}</Modal>
  )
}
