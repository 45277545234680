import React from 'react'
import { Upload } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUpload } from '@fortawesome/pro-solid-svg-icons'
import messenger from 'libs/messenger'
import BaseField from './BaseField'
import { BaseButton } from 'components/buttons'


export default function FieldFile(props) {
  /* Input File
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <array of Files> Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * isMultiple = <boolean> Optional
   * buttonText = <string> Optional
   */
  const isMounted = React.useRef(true)
  const fileList = Array.isArray(props.value) ? props.value : []

  // FUNCTIONS
  const previewFile = file => new Promise(resolve => {
    resolve(window.URL.createObjectURL(file))
  })

  const isImageUrl = file => {
    return file.type.startsWith("image/")
  }

  let currentSize = fileList.reduce((size, file) => size + file.size, 0)
  const beforeUpload = file => {
    // Check size
    const isTooBig = (currentSize + file.size) / 1024 / 1024 > 500
    if (isTooBig) {
      messenger.showError(null, "Files need to be smaller than 500MB.")
    }
    // Check if multiple
    const isTooMany = !props.isMultiple && fileList.length > 0
    if (isTooMany) {
      messenger.showError(null, "Only one file is allowed.")
    }
    // Check if existing
    const isExisting = fileList.length > 0 && fileList.some(f => f.name === file.name)
    if (isExisting) {
      messenger.showError(null, 'The file "' + file.name + '" is already in the list.')
    }
    // Check if all valid
    const isValid = !isTooBig && !isTooMany && !isExisting
    if (isValid) {
      currentSize += file.size
    }
    return isValid
  }

  const fakeRequest = request => {
    setTimeout(() => request.onSuccess(), 0)
  }

  const onChange = info => {
    props.onChange({
      target: {
        value: info.fileList.filter(
          f => f.status === "done" || f.status === "saved" || f.status === "uploading"
        )
      }
    })
  }

  const onRemove = file => {
    if (file.thumbUrl?.startsWith("blob:")) {
      window.URL.revokeObjectURL(file.thumbUrl)
    }
  }

  // INITIALIZATION
  React.useEffect(() => () => {
    isMounted.current = false
  }, [])
  React.useEffect(() => () => {
    if (!isMounted.current && fileList.length > 0) {
      for (let file of fileList) {
        onRemove(file)
      }
    }
  }, [isMounted, fileList])

  // RENDER
  return (
    <BaseField
      className={props.className}
      label={props.label}
      input={
        <Upload
          className="custom-field-file"
          name={props.name}
          multiple={props.isMultiple}
          accept="image/*, video/*, text/plain, text/csv, .pdf, .xls, .xslx, .doc, .docx, .ppt, .pptx, .vsdx"
          listType="picture"
          fileList={fileList}
          previewFile={previewFile}
          isImageUrl={isImageUrl}
          beforeUpload={beforeUpload}
          customRequest={fakeRequest}
          onChange={onChange}
          onRemove={onRemove}
        >
          {(props.isMultiple || fileList.length === 0) && (
            <BaseButton
              type="primary"
              text={
                <>
                  <FontAwesomeIcon className="mr-2" icon={faUpload} />
                  {props.buttonText || "Upload"}
                </>
              }
              title={props.buttonText || "Upload"}
              disabled={!props.isMultiple && fileList.length > 0}
            />
          )}
        </Upload>
      }
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
    />
  )
}
