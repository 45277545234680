import './Header.scss'
import React from 'react'
import logo from 'assets/images/eventopsLogo.png'


export default function Header(props) {
  const logoUrl = props.company?.new_logo_src || props.company?.logo || logo


  return (
    <header className="app-layout-header">
      {props.menuIcon}

      <a className="logo" href="/">
        <img
          title={props.company?.name || 'EventOPS'}
          alt={props.company?.name || 'EventOPS'}
          src={logoUrl}
        />
        {props.company?.display_name}
      </a>

      <div className="nav navbar-nav ml-auto notification mr-4">
        {props.notifications}
      </div>
    </header>
  )
}

Header.defaultProps = {
  company: { },
  menuIcon: null,
  notifications: null
}
