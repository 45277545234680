import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { isDict } from 'libs/utils'
import Layout from 'components/layout'
// APPs
import Auth from 'apps/auth'
import Forms from 'apps/forms'
import Participants from 'apps/participants'
import { LoadingRoot } from 'apps/loading'
import { NotFoundRoot } from 'apps/notfound'
// API
import api, { getDefaultData } from 'libs/api'
// Style
import './App.scss'

class LoadDataController {
  wasCanceled = false

  cancel = () => this.wasCanceled = true
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      initialHistoryIndex: this.props.history.length,
      currentPathname: window.location.pathname,
      controller: null,
      showDebug: false,
      isInitializing: true,
      isLoading: true,
      company: null,
      account: null,
      links: [],
      data: null,
      permissions: {}
    }
  }

  componentDidMount() {
    this.initiateApp()
  }

  initiateApp = async () => {
    // TODO: GET TOKEN (If coming from a login redirect)
    //if (window.location.search.includes("token=")) {
    //  // Get token
    //  const ssoToken = window.location.search.split("token=")[1].split("&")[0]
    //  // Remove token from URL
    //  window.history.replaceState(null, null, window.location.pathname)
    //  // Try to login with Core token
    //  await loginWithSsoToken(ssoToken)
    //  // Close window
    //  window.open("", "_self").close()
    //  return
    //}

    // SET STATE VARIABLES
    // Read hash
    const showDebug = this.props.location.hash.toLowerCase().includes("debug")
    // Set/Reset state
    this.setState({
      showDebug: showDebug,
      isInitializing: true,
      isLoading: true,
      company: null,
      account: null,
      links: [],
      data: null,
      permissions: {}
    })

    // SET GLOBAL VARIABLES
    // Add navigation functions
    window.navigateTo = (path, keepHash = true, keepSearch = false, reload = true) => {
      // Check path
      if (!path.startsWith('/')) {
        path = '/' + path
      }
      // Check if need to navigate
      if (path !== window.location.pathname) {
        // Set the new location
        this.props.history.push(
          path
          + (keepHash ? window.location.hash : '')
          + (keepSearch ? window.location.search : '')
        )
        if (reload) {
          // Reload data
          this.reloadData()
        } else {
          // Save new location
          this.setState({ currentPathname: window.location.pathname })
        }
      }
    }
    window.navigateBack = () => {
      if (this.props.history.length <= this.state.initialHistoryIndex) {
        this.props.history.replace(
          '/' + window.location.pathname.split('/').filter(path => path !== '').slice(0, -1).join('/')
          + window.location.hash
        )
        this.reloadData()
      } else {
        this.props.history.goBack()
      }
    }
    // Add navigation listener
    window.onpopstate = event => {
      this.reloadData()
    }
    // Add login listener
    window.onstorage = async storage => {
      if (
        !this.state.isLoading &&
        (storage.key === null || storage.key === "token_exp")
      ) {
        this.loadData()
      }
    }
    // GlobalVariable
    window.eventops = {
      app: window.location.hostname.split(".").slice(-2, -1)[0] === "forms" ? "forms" : "participants",
      login: this.login,
      logout: this.logout,
      reload: this.reloadData
    }

    // LOAD DATA
    this.loadData()
  }

  loadData = async () => {
    // Create controller
    const controller = new LoadDataController()
    this.setState({ controller: controller })

    // Set intial state
    let initialState = {
      isInitializing: false,
      isLoading: false,
      company: null,
      account: null,
      links: [],
      data: null,
      permissions: {},
    }

    // Get default data
    getDefaultData().then(defaultData => {
      if (!controller.wasCanceled) {
        initialState = {
          ...initialState,
          ...defaultData
        }

        // Save intial state
        this.setState(initialState)
      } else {
        console.info('Loading data canceled')
      }
    })
  }

  reloadData = () => {
    // Cancel last controller
    this.state.controller?.cancel()
    // Load new data
    this.setState({
      currentPathname: window.location.pathname,
      isLoading: true,
      data: null
    })
    this.loadData()
  }

  updateStateKey = (key, object) => {
    this.setState(prevState => {
      // If function
      if (typeof object === "function") {
        const resultObject = object(prevState[key])
        if (resultObject === undefined) {
          return
        }
        if (isDict(resultObject)) {
          return { [key]: Object.assign(prevState[key], resultObject) }
        }
        return { [key]: resultObject }
      }
      // If value
      if (isDict(object)) {
        return { [key]: Object.assign(prevState[key], object) }
      }
      return { [key]: object }
    })
  };
  setCompany = nextCompany => this.updateStateKey('company', nextCompany)
  setAccount = nextAccount => this.updateStateKey('account', nextAccount)
  setLinks = nextLinks => this.updateStateKey('links', nextLinks)
  setData = nextData => this.updateStateKey('data', nextData)
  setPermissions = nextPermissions => this.updateStateKey('permissions', nextPermissions)
  setIsLoading = nextIsLoading => this.updateStateKey('isLoading', nextIsLoading)

  login = async (email, password, rememberMe = false) => {
    const [success, response] = await api.post('auth/login/', {
      email: email,
      password: password,
      remember_me: rememberMe
    })
    if (success) {
      this.reloadData()
    }
  }

  logout = async (reload = true) => {
    const [success, response] = await api.get('auth/logout/')
    if (success) {
      this.setAccount(null)
      if (reload) {
        this.reloadData()
      }
    }
  }

  render() {
    // Initializing
    if (this.state.isInitializing) {
      return <LoadingRoot />
    }
    // Can't get company
    if (!this.state.company) { // TODO: CREATE VIEW
      return <NotFoundRoot />
    }
    // Creating password
    if (window.location.pathname.startsWith('/create-password/')) {
      if (this.state.account) {
        return <Auth
          account={this.state.account}
          clearAccount={() => this.setAccount(null)}
        />
      } else {
        return <Auth cancelCreatePassword={true} />
      }
    }
    // Need to login
    if (!this.state.account && this.state.permissions?.view_permission === false) {
      return <Auth />
    }
    // Load APPs
    const baseProps = {
      company: this.state.company,
      setCompany: this.setCompany,
      account: this.state.account,
      setAccount: this.setAccount,
      links: this.state.links,
      setLinks: this.setLinks,
      data: this.state.data,
      setData: this.setData,
      permissions: this.state.permissions,
      setPermissions: this.setPermissions,
      isLoading: this.state.isLoading || this.state.currentPathname !== this.props.location.pathname,
      setIsLoading: this.setIsLoading,
    }
    return (
      <Layout {...baseProps}>
        {window.eventops.app === 'forms' ?
          <Forms {...baseProps} />
          :
          <Participants {...baseProps} />
        }
      </Layout >
    )
  }
}

export default withRouter(App)