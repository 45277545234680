import React from 'react'
import classNames from 'classnames'


export default function BaseField(props) {
  /* Base Input
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * input = <JSX content>
   * required = <boolean> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   */

  return (
    <div className={classNames("form-group", props.className)}>

      {props.label && (
        <label className="custom-field-label">
          {props.label}
          {props.required ? <span className="text-danger"> *</span> : null}
        </label>
      )}

      {props.help && props.helpAboveInput &&
        <small className="form-text text-muted mt-0 mb-2 custom-field-help">{props.help}</small>
      }

      {props.input}

      <div
        className="valid-feedback custom-field-valid"
        style={{ display: props.valid === true ? "block" : null }}
      >
        {props.validMessage}
      </div>

      <div
        className="invalid-feedback custom-field-invalid"
        style={{ display: props.valid === false ? "block" : null }}
      >
        {props.invalidMessage}
      </div>

      {props.help && !props.helpAboveInput &&
        <small className="form-text text-muted custom-field-help">{props.help}</small>
      }

    </div>
  )
}
