// SCRIPTS
import React from 'react'
import { timeZoneValues } from './base_form'
import { copyDeep, isEqual, isEmpty } from 'libs/utils'
import api from 'libs/api'
import vm from 'vm'
// COMPONENTS
import messenger from 'libs/messenger'
import {
  ButtonDanger,
  ButtonPrimary,
  ButtonSuccess,
} from 'components/buttons'
import {
  FieldText,
  FieldTextArea,
  FieldEmail,
  FieldRadio,
  FieldCheckbox,
  FieldDropdown,
  FieldDatalist,
  FieldDate,
  FieldTime,
  FieldNumber,
  FieldTel,
  FieldFile,
  FieldMarkdown,
  FieldIframe,
  FieldHidden,
  FieldFetch,
} from 'components/fields'
import Markdown from 'components/markdown'
//import Widget from './Widget'
// React Bootstrap
//import ProgressBar from 'react-bootstrap/ProgressBar'
import { Progress } from 'antd'
// Prime React
import Dialog from 'components/dialog'
import { Spinner } from 'components/spinner'
const timeZoneHere = Intl.DateTimeFormat().resolvedOptions().timeZone
const search = new URLSearchParams(window.location.search)


const Widget = () => null


export default function Form(props) {
  /* Form Component
   * Expected Props:
   * user = <User JSON>
   * intro = <string> Optional
   * form = <Form JSON> Required
   * isFormValid = <boolean> Required
   * isEditing = <boolean>
   */
  // Init Functions
  const getDefaultValue = (field) => search.get(field.mapField) !== null ? search.get(field.mapField) :
    field.defaultValue || (
      field.mapField === "timezone" ? timeZoneHere :
        field.mapField === "first_name" ? (props.user?.first_name) || "" :
          field.mapField === "last_name" ? (props.user?.last_name) || "" :
            field.mapField === "email" ? (props.user?.email) || "" : ""
    )
  // Pages Variables
  const [pages, setPages] = React.useState([0])
  const [currentPage, setCurrentPage] = React.useState(0)
  const currentPageIndex = (currentPage < pages.length) ? pages[currentPage] : (pages.length > 0) ? (pages.length - 1) : 0
  // Fields Variables
  const [prevFields, setPrevFields] = React.useState(copyDeep(props.form.fields))
  const [lastChanged, setLastChanged] = React.useState()
  const [fieldsData, setFieldsData] = React.useState(
    props.form.fields instanceof Array ? props.form.fields.reduce((result, page, pageIndex) => {
      // Check Page
      if (page.conditions instanceof Array && page.fields instanceof Array) {
        // Get Fields Data
        return page.fields.reduce((pageResult, field) => {
          pageResult[field.mapField] = {
            page: pageIndex,
            type: field.type,
            value: getDefaultValue(field),
            minValue: undefined,
            maxValue: undefined,
            render: undefined,
            valid: undefined,
            invalidMessage: ""
          }
          return pageResult
        }, result)
      }
      // Ignore this page if not valid
      return result
    }, {}) : {}
  )
  // Form Variables
  const formApi = props.form?.apiPath
  const canBeSaved = React.useMemo(() => props.form.can_be_saved, [props.form])
  const [isLoading, setIsLoading] = React.useState(true)
  const [showValidations, setShowValidations] = React.useState(false)
  const [readyForSubmit, setReadyForSubmit] = React.useState(false)
  const [showModal, setShowModal] = React.useState(false)
  const [showLoadSavedModal, setShowLoadSavedModal] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)
  const [isSubmitting, setIsSubmitting] = React.useState(false)
  const isFormValid = props.isFormValid && !isEmpty(fieldsData)

  // INITIALIZATION
  // Get saved values
  React.useEffect(() => {
    if (props.form?.saved_data) {
      setShowLoadSavedModal(true)
    }
  }, [])

  // Load Pages and Fields
  const initForm = () => {
    if (!isEqual(prevFields, props.form.fields)) {
      // Get new values
      const nextFieldsData = props.form.fields instanceof Array ?
        props.form.fields.reduce((result, page, pageIndex) => {
          // Check Page
          if (page.conditions instanceof Array && page.fields instanceof Array) {
            // Get Fields Data
            return page.fields.reduce((pageResult, field) => {
              pageResult[field.mapField] = (fieldsData && field.mapField in fieldsData) ?
                Object.assign(fieldsData[field.mapField], {
                  page: pageIndex,
                  type: field.type,
                })
                :
                {
                  page: pageIndex,
                  type: field.type,
                  value: getDefaultValue(field),
                  minValue: undefined,
                  maxValue: undefined,
                  render: undefined,
                  valid: undefined,
                  invalidMessage: ""
                }
              return pageResult
            }, result)
          }
          // Ignore this page if not valid
          return result
        }, {}) : {}
      // Save Fields Data if changed
      if (!isEqual(fieldsData, nextFieldsData)) {
        setFieldsData(nextFieldsData)
      } else {
        // Run Validations
        runValidations()
      }
      // Update prevFields
      setPrevFields(copyDeep(props.form.fields))
    }
  }
  React.useEffect(() => {
    const delayedInit = setTimeout(initForm, 500)
    return () => clearTimeout(delayedInit)
  })

  // PAGES
  const nextPage = () => {
    if (currentPage < pages.length - 1) {
      setLastChanged()
      setCurrentPage(currentPage + 1)
      setShowValidations(false)
    }
  }

  const prevPage = () => {
    if (currentPage > 0) {
      setLastChanged()
      setCurrentPage(currentPage - 1)
      setShowValidations(false)
    }
  }

  const currentPageFields = isFormValid ? (props.form.fields[currentPageIndex]?.fields || []) : []

  // UTILS
  const getFieldData = (mapField, invalidFieldResponse = {}) => {
    const fieldData = fieldsData[mapField]
    if (!fieldData) {
      console.warn("Failed to get data of field '" + mapField + "'.")
      return invalidFieldResponse
    }
    return fieldData
  }

  const getFieldValue = (mapField, invalidFieldResponse = "") => {
    const fieldData = fieldsData[mapField]
    if (!fieldData) {
      console.warn("Failed to get value of field '" + mapField + "'.")
      return invalidFieldResponse
    }
    return fieldData.value
  }

  const runCodeSafelly = (code, value = null) => {
    try {
      const result = 'result_' + Math.floor(Math.random() * 10000)
      // Create Context
      const context = {
        value: value,
        GetValue: function (mapField) {
          return getFieldValue(mapField)
        },
        Now: function (timeZone) {
          ////return new Date().toLocaleTimeString("en-US", {
          ////  hour: "2-digit",
          ////  minute: "2-digit",
          ////  hour12: false,
          ////  timeZone: timeZone || timeZoneHere
          ////});
          const options = timeZone ? { timeZone: timeZone } : {}
          const now = new Date().toLocaleTimeString("en-US", options).split(":")
          if (now[0].length < 2) { // Make sure hours have 2-digits
            now[0] = "0" + now[0]
          }
          return (now[2].split(" ")[1] === "PM" ? Number(now[0]) + 12 : now[0]) + ":" + now[1]
        },
        AddHours: function (time, hours) {
          if (time === "") {
            return null
          }
          let newTime = time.split(":")
          newTime[0] = Number(newTime[0]) + hours
          if (newTime[0] >= 24) {
            newTime[0] = newTime[0] - 24
          } else if (newTime[0].length < 2) { // Make sure hours have 2-digits
            newTime[0] = "0" + newTime[0]
          }
          return newTime[0] + ":" + newTime[1]
        },
        Today: function (timeZone) {
          const options = (timeZone ?
            {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
              timeZone: timeZone
            }
            :
            {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }
          )
          const today = new Date().toLocaleDateString("en-US", options).split("/")
          return today[2] + "-" + today[0] + "-" + today[1]
        },
        AddDays: function (date, days) {
          if (date === "") {
            return null
          }
          let dateToAdd = new Date(date)
          dateToAdd.setDate(dateToAdd.getDate() + days)
          return dateToAdd.toISOString().split('T')[0]
        },
        [result]: null
      }
      const fullCode = (window.inIframe ? // Can't change constructor in iFrame
        "(() => { Function = undefined; })();"
        :
        `(() => {
          Function = undefined;
          const keys = Object.getOwnPropertyNames(this).concat(['constructor']);
          keys.forEach(key => {
            const item = this[key];
            if (!item || typeof item.constructor !== 'function') return;
            this[key].constructor = undefined;
          });
        })();`
      ) + result + "=(() => " + code + ")()"
      // Run code
      vm.runInNewContext(fullCode, context)
      // Get result
      if ((typeof context[result] === "string" && context[result] !== "") || typeof context[result] === "number") {
        return context[result]
      }
      return null
    }
    catch (err) {
      // If any error
      console.error("Invalid Code: " + code + "\nError: " + err)
      return null
    }
  }

  // CHECK RENDER CONDITIONS
  const checkCondition = (condition, mapField, pageIndex) => {
    /* conditon: {
     *   type: <conditionType>,
     *   field: <field>,
     *   operator: <conditionOperator>,
     *   value: <any>
     * }
     */
    // Test if is an array of conditions
    if (condition.conditions) {
      return checkConditions(condition.conditions, mapField, pageIndex)
    }
    // Get field value
    const fieldValue = getFieldValue(condition.field, null)
    if (fieldValue === null) {
      console.error(
        "Form Condition Error: '" + condition.field + "' is not a valid Field." + (
          pageIndex === null ?
            "\nThe field '" + mapField + "' will be ignored."
            :
            "\nThe page with index '" + pageIndex + "' will be ignored."
        )
      )
      return false
    }
    // Make validation
    //console.log(fieldValue + " " + condition.operator + " " + condition.value)
    switch (condition.operator) {
      case "==":
        return (fieldValue == condition.value)
      case "!=":
        return (fieldValue != condition.value)
      case ">=":
        return (fieldValue >= condition.value)
      case "<=":
        return (fieldValue <= condition.value)
      case ">":
        return (fieldValue > condition.value)
      case "<":
        return (fieldValue < condition.value)
      case "()":
        return fieldValue && fieldValue.includes(condition.value)
      case "!()":
        return !(fieldValue && fieldValue.includes(condition.value))
      case "regex":
        try {
          return (new RegExp(condition.value)).test(String(fieldValue))
        } catch (err) {
          console.error(
            "Form Condition Error: '" + condition.value + "' is not a valid regex." + (
              pageIndex === null ?
                "\nThe field '" + mapField + "' will be ignored."
                :
                "\nThe page with index '" + pageIndex + "' will be ignored."
            )
          )
          return false
        }
      default:
        console.error(
          "Form Condition Error: '" + condition.operator + "' is not a valid conditional operator." + (
            pageIndex === null ?
              "\nThe field '" + mapField + "' will be ignored."
              :
              "\nThe page with index '" + pageIndex + "' will be ignored."
          )
        )
    }
    return false
  }

  const checkConditions = (conditions, mapField, pageIndex = null) => {
    /* conditions: [<conditon>]
     * conditon: {
     *   type: <conditionType>,
     *   field: <field>,
     *   operator: <conditionOperator>,
     *   value: <any>
     * }
     * 
     */
    // Test if any condition
    if (!conditions || !conditions.length) {
      return true
    }
    // Test for condition type error
    const typeErrors = conditions
      .filter(condition => condition.type !== "or")
      .filter(condition => condition.type !== "and")
    if (typeErrors.length !== 0) {
      const errosList = [...new Set(typeErrors.map(c => c.type))]
      console.error(
        "Form Condition Error: '" + (
          errosList.length === 1 ?
            errosList[0] + "' is not a valid conditional type."
            :
            errosList.join("', '") + "' are not valid conditional types."
        ) + (
          pageIndex === null ?
            "\nThe field '" + mapField + "' will be ignored."
            :
            "\nThe page with index '" + pageIndex + "' will be ignored."
        )
      )
      return false
    }
    // OR tests
    const orConditions = conditions.filter(condition => condition.type === "or")
    if (orConditions.length !== 0) {
      for (let condition of orConditions) {
        if (checkCondition(condition, mapField, pageIndex)) {
          return true
        }
      }
      return false
    }
    // AND tests
    const andConditions = conditions.filter(condition => condition.type === "and")
    for (let condition of andConditions) {
      if (checkCondition(condition, mapField, pageIndex) === false) {
        return false
      }
    }
    return true
  }

  const checkPagesConditions = () => {
    const nextPages = props.form.fields instanceof Array ? props.form.fields.reduce((result, page, pageIndex) => {
      if (pageIndex === 0 || ( // First page will ignore conditions and be shown always
        page.conditions instanceof Array &&
        page.fields instanceof Array &&
        page.fields.length > 0 &&
        checkConditions(page.conditions, null, pageIndex)
      )) {
        result.push(pageIndex)
      }
      return result
    }, []) : [0]
    if (!isEqual(pages, nextPages)) {
      setPages(nextPages)
    }
  }

  // VALIDATIONS
  const checkValidationCondition = (condition, dataValue, mapField) => {
    /* conditon: {
     *   type: <conditionType>,
     *   field: <false(self) or fieldName>,
     *   operator: <conditionOperator>,
     *   checkField: <false(checkValue) or fieldName>,
     *   checkValue: <any>
     *   invalidMessage: <string> // Only works when type in "and"
     * }
     */
    // Test if is an array of conditions
    if (condition.conditions) {
      return checkValidationConditions(condition.conditions, dataValue, mapField)
    }
    // Get field value
    let fieldValue
    if (condition.field) {
      fieldValue = getFieldValue(condition.field, null)
      if (fieldValue === null) {
        console.error(
          "Form Validation Error: '" + condition.field + "' is not a valid Field." +
          "\nThe field '" + mapField + "' can not be validated."
        )
        return [false, undefined]
      }
    } else {
      fieldValue = dataValue
    }
    // Get check value
    let checkValue
    if (condition.checkField) {
      checkValue = getFieldValue(condition.checkField, null)
      if (checkValue === null) {
        console.error(
          "Form Validation Error: '" + condition.checkField + "' is not a valid Field." +
          "\nThe field '" + mapField + "' can not be validated."
        )
        return [false, undefined]
      }
      if (!checkValue) { return [null, undefined] } // Dependent field has no value yet
    } else {
      checkValue = condition.checkValue
    }
    // Make validation
    //console.log(fieldValue + " " + condition.operator + " " + checkValue)
    switch (condition.operator) {
      case "==":
        return [(fieldValue == checkValue), undefined]
      case "!=":
        return [(fieldValue != checkValue), undefined]
      case ">=":
        return [(fieldValue >= checkValue), undefined]
      case "<=":
        return [(fieldValue <= checkValue), undefined]
      case ">":
        return [(fieldValue > checkValue), undefined]
      case "<":
        return [(fieldValue < checkValue), undefined]
      case "()":
        return [fieldValue.includes(checkValue), undefined]
      case "!()":
        return [!fieldValue.includes(checkValue), undefined]
      case "regex":
        try {
          return [(new RegExp(checkValue)).test(String(fieldValue)), undefined]
        } catch (err) {
          console.error(
            "Form Validation Error: '" + condition.value + "' is not a valid regex." +
            "\nThe field '" + mapField + "' can not be validated."
          )
          return [false, undefined]
        }
      default:
        console.error(
          "Form Validation Error: '" + condition.operator + "' is not a valid conditional operator." +
          "\nThe field '" + mapField + "' can not be validated."
        )
    }
    return [false, undefined]
  }

  const checkValidationConditions = (conditions, dataValue, mapField) => {
    /* conditions: [<conditon>]
     * conditon: {
     *   type: <conditionType>,
     *   field: <false(self) or fieldName>,
     *   operator: <conditionOperator>,
     *   checkField: <false(checkValue) or fieldName>,
     *   checkValue: <any>
     *   invalidMessage: <string> // Only works when type in "and"
     * }
     */
    // Test for condition type error
    const typeErrors = conditions
      .filter(condition => { return condition.type !== "or" })
      .filter(condition => { return condition.type !== "and" })

    if (typeErrors.length !== 0) {
      const errosList = [...new Set(typeErrors.map(c => c.type))]
      console.error(
        "Form Validation Error: '" + (
          errosList.length === 1 ?
            errosList[0] + "' is not a valid conditional type."
            :
            errosList.join("', '") + "' are not valid conditional types."
        ) + "\nThe field '" + mapField + "' can not be validated."
      )
      return [false, undefined]
    }
    let validationResult
    // OR tests
    const orConditions = conditions.filter(condition => { return condition.type === "or" })
    if (orConditions.length !== 0) {
      for (let condition of orConditions) {
        validationResult = checkValidationCondition(condition, dataValue, mapField)
        if (validationResult[0] !== false) {
          return validationResult
        }
      }
      return [false, undefined]
    }
    // AND tests
    const andConditions = conditions.filter(condition => { return condition.type === "and" })
    for (let condition of andConditions) {
      validationResult = checkValidationCondition(condition, dataValue, mapField)
      if (validationResult[0] === false) {
        return [false, validationResult[1] || condition.invalidMessage || undefined]
      }
    }
    return [true, undefined]
  }

  const checkFieldValidation = (field, dataValue) => {
    // Check required validation
    if (field.required && !dataValue) {
      return [false, undefined]
    } else if (field.type === "checkbox") {
      for (let value of field.values) {
        if (value.required && (!dataValue || !dataValue.includes(value.value))) {
          return [false, value.invalidMessage || undefined]
        }
      }
    }
    // Skip Markdowns, iframe and Files
    if (["file", "markdown", "iframe"].includes(field.type)) {
      return [true, undefined]
    }
    // Check other validations
    return checkValidationConditions(
      field.validation.conditions,
      dataValue,
      field.mapField
    )
  }

  // Real-time Validations and conditions
  const runValidations = () => {
    const nextFieldsData = copyDeep(fieldsData)
    let changed = false
    let conditionsResult
    let validationResult
    let invalidMessage
    let minValue
    let maxValue
    let isFieldValid
    let isPageValid = true

    // Check pages
    checkPagesConditions()

    // Check fields
    for (let field of currentPageFields) {
      // FIELD DATA
      // Check for field data
      if (!nextFieldsData[field.mapField]) {
        if (window.eventops?.showDebug) {
          console.debug(
            "\u2716 Form Validation: Failed to get '" + field.mapField + "' data.\nThis field can not be validated.\nIgnore this error if you're adding this field. This field's data will be added in the next rendering cycle."
          )
        }
        continue
      }

      // HIDDEN FIELDS
      if (field.hidden) {
        if (search.get(field.mapField) === null && field.valueConditions?.length) {
          // Get Default Value
          conditionsResult = getDefaultValue(field)
          // Check Default Values conditions
          for (let valueCondition of field.valueConditions) {
            if (checkConditions(valueCondition.conditions, field.mapField)) {
              conditionsResult = valueCondition.value
              break
            }
          }
          // Save if Default Values changed
          if (nextFieldsData[field.mapField].value !== conditionsResult) {
            nextFieldsData[field.mapField].value = conditionsResult
            changed = true
          }
        }
        continue
      }

      // CONDITIONS
      // Get conditions
      conditionsResult = checkConditions(field.conditions, field.mapField)
      // Save conditions
      if (nextFieldsData[field.mapField].render !== conditionsResult) {
        nextFieldsData[field.mapField].render = conditionsResult
        changed = true
      }
      // Check conditions
      if (!conditionsResult) {
        nextFieldsData[field.mapField].value = getDefaultValue(field)
        continue
      }

      // MIN AND MAX VALUES
      if (["date", "time", "number"].includes(field.type)) {
        // Get min value
        if (field.minValue && (
          field.minValue.field !== "" || field.minValue.formula !== ""
        )) {
          const value = field.minValue.field === "" ?
            nextFieldsData[field.mapField].value
            :
            getFieldValue(field.minValue.field)
          if (field.minValue.formula !== "") {
            minValue = runCodeSafelly(
              field.minValue.formula,
              value,
            )
          } else {
            minValue = value ? value : null
          }

        } else {
          minValue = undefined
        }
        // Get max value
        if (field.maxValue && (
          field.maxValue.field !== "" || field.maxValue.formula !== ""
        )) {
          const value = field.maxValue.field === "" ?
            nextFieldsData[field.mapField].value
            :
            getFieldValue(field.maxValue.field)
          if (field.maxValue.formula !== "") {
            maxValue = runCodeSafelly(
              field.maxValue.formula,
              value,
            )
          } else {
            maxValue = value ? value : null
          }
        } else {
          maxValue = undefined
        }
        // Check results
        if (minValue !== null && maxValue !== null && minValue > maxValue) {
          // TODO: INSTEAD OF CLEARING, MARK AS INVALID????
          if (nextFieldsData[field.mapField].value !== "") {
            nextFieldsData[field.mapField].value = ""
            changed = true
          }
        }
        // Save results
        if (nextFieldsData[field.mapField].minValue !== minValue) {
          nextFieldsData[field.mapField].minValue = minValue
          changed = true
        }
        if (nextFieldsData[field.mapField].maxValue !== maxValue) {
          nextFieldsData[field.mapField].maxValue = maxValue
          changed = true
        }
        // Check min value
        if (
          minValue !== null && nextFieldsData[field.mapField].value !== "" &&
          minValue > nextFieldsData[field.mapField].value
        ) {
          nextFieldsData[field.mapField].value = minValue
          changed = true
        }
        // Check max value
        if (
          maxValue !== null && nextFieldsData[field.mapField].value !== "" &&
          maxValue < nextFieldsData[field.mapField].value
        ) {
          nextFieldsData[field.mapField].value = maxValue
          changed = true
        }
      }

      // API VALUES
      if (["dropdown_api", "datalist_api"].includes(field.type)) {
        // Get Field API
        const fieldApi = "api" in field ? copyDeep(field.api) : {
          method: "GET",
          url: "",
          authorization: "",
          request: null,
          response: {
            array: [],
            label: [],
            value: []
          }
        }

        // Get URL
        if (fieldApi.url?.includes("{")) {
          const mapFields = new Set(fieldApi.url.match(/{.*?}/g)?.filter(mf => mf !== "{tenant_url}") || [])
          for (let mapField of mapFields) {
            mapField = mapField.replace(/^{|}$/g, "")
            if (mapField in nextFieldsData) {
              fieldApi.url = fieldApi.url.replace("{" + mapField + "}", String(nextFieldsData[mapField].value).replace(/"/g, '\\"'))
            } else {
              fieldApi.url = fieldApi.url.replace('"{' + mapField + '}"', null).replace("{" + mapField + "}", "")
            }
          }
        }

        // Get Request
        if (fieldApi.method === "POST" && fieldApi.request) {
          fieldApi.request = JSON.stringify(fieldApi.request)
          const mapFields = new Set((fieldApi.request.match(/".*?"/g) || []).reduce((result, str) => {
            const subValues = str.match(/{.*?}/g)
            return subValues ? result.concat(subValues) : result
          }, []))
          for (let mapField of mapFields) {
            mapField = mapField.replace(/^{|}$/g, "")
            if (mapField in nextFieldsData) {
              fieldApi.request = fieldApi.request.replace("{" + mapField + "}", String(nextFieldsData[mapField].value).replace(/"/g, '\\"'))
            } else {
              fieldApi.request = fieldApi.request.replace('"{' + mapField + '}"', null).replace("{" + mapField + "}", "")
            }
          }
          fieldApi.request = JSON.parse(fieldApi.request)
        }

        // Check changes
        if (!nextFieldsData[field.mapField].api || !isEqual(nextFieldsData[field.mapField].api, fieldApi)) {
          nextFieldsData[field.mapField].api = fieldApi
          changed = true
        }
      }

      // VALIDATIONS
      // Get validations
      [validationResult, invalidMessage] = checkFieldValidation(field, nextFieldsData[field.mapField].value)
      // Check validations result
      if (showValidations) {
        isFieldValid = validationResult
      } else if (
        field.mapField === lastChanged
        || nextFieldsData[field.mapField].valid !== undefined
        || (nextFieldsData[field.mapField].value !== "" && nextFieldsData[field.mapField].value?.length)
      ) {
        isFieldValid = !validationResult ? validationResult : null
      } else {
        isFieldValid = undefined
      }
      // Save result
      if (nextFieldsData[field.mapField].valid !== isFieldValid) {
        nextFieldsData[field.mapField].valid = isFieldValid
        changed = true
      }
      if (nextFieldsData[field.mapField].invalidMessage !== invalidMessage) {
        nextFieldsData[field.mapField].invalidMessage = invalidMessage
        changed = true
      }
      if (!validationResult) {
        isPageValid = false
      }
    }

    // Check form
    if (readyForSubmit !== isPageValid) {
      setReadyForSubmit(isPageValid)
    }

    // Save if changed
    if (changed) {
      setFieldsData(nextFieldsData)
    }

    // Unset is loading
    if (isLoading) {
      setIsLoading(false)
    }
  }

  // VALIDATIONS
  React.useEffect(() => {
    // Get last changed data
    const [lastChangedType, lastChangedValue] = fieldsData[lastChanged] ?
      [fieldsData[lastChanged].type, fieldsData[lastChanged].value] : ["", ""]
    // Run asynchronous validations
    if (
      // Clearing values
      lastChangedValue !== ""
      // Dropdown, radio and checkbox" types
      && !['dropdown', 'dropdown_api', 'datalist', 'datalist_api', "radio", "checkbox"].includes(lastChangedType)
    ) {
      if (window.eventops?.showDebug) {
        console.info("Form: Starting validations in 500ms.")
      }
      const delayedChange = setTimeout(runValidations, 500)
      return () => clearTimeout(delayedChange)
    }
    if (window.eventops?.showDebug) {
      console.info("Form: Starting validations.")
    }
    // Run synchronous validations
    runValidations()
  }, [showValidations, fieldsData, currentPageIndex, isFormValid])

  // SAVE
  const handleSave = async event => {
    // Prevent page to be reloaded
    event.preventDefault()
    // Set isSaving flag
    setIsSaving(true)
    // Initiate Form Data
    let formData = new FormData()
    // Read fields
    for (let mapField in fieldsData) {
      switch (fieldsData[mapField].type) {
        case "spaces_widget":
          // Will not save // TODO: Should save?
          break
        case "file": {
          const fileValue = []
          for (let file of fieldsData[mapField].value) {
            if (file.status === "done") {
              formData.append(file.uid, file.originFileObj)
            }
            fileValue.push({
              uid: file.uid,
              lastModified: file.lastModified,
              lastModifiedDate: file.lastModifiedDate,
              name: file.name,
              size: file.size,
              type: file.type,
              status: file.status
            })
          }
          formData.append(mapField, JSON.stringify(fileValue))
          break
        }
        case "markdown":
          break
        case "iframe":
          break
        default:
          formData.append(mapField, fieldsData[mapField].value)
      }
    }
    // Save form
    api.post(formApi + "save/", formData).then(([success, response]) => {
      // Check results
      if (!success) {
        messenger.showError("Failed to save form", "Please try again or contact a staff member to know more about this issue.")
      }
      // Unset isSaving flag
      setIsSaving(false)
    })
  }

  // SUBMIT
  const handleSubmit = async event => {
    // Prevent page to be reloaded
    event.preventDefault()
    // Set isSubmitting flag
    setIsSubmitting(true)
    // Check if form is valid
    if (!readyForSubmit) {
      setShowValidations(true)
      return
    }
    // Initiate Form Data
    let formData = new FormData()
    // Read fields
    for (let mapField in fieldsData) {
      if (fieldsData[mapField].render !== false) {
        switch (fieldsData[mapField].type) {
          case "spaces_widget":
            formData.append(mapField, JSON.stringify(fieldsData[mapField].value))
            break
          case "file": {
            const fileValue = []
            for (let file of fieldsData[mapField].value) {
              if (file.status === "done") {
                formData.append(file.uid, file.originFileObj)
              }
              fileValue.push({
                uid: file.uid,
                lastModified: file.lastModified,
                lastModifiedDate: file.lastModifiedDate,
                name: file.name,
                size: file.size,
                type: file.type,
                status: file.status
              })
            }
            formData.append(mapField, JSON.stringify(fileValue))
            break
          }
          case "markdown":
            break
          case "iframe":
            break
          default:
            formData.append(mapField, fieldsData[mapField].value)
        }
      }
    }
    // Submit form
    api.post(formApi, formData).then(([success, response]) => {
      // Check results
      if (success) {
        setShowModal(true)
      } else if (!response || !response.messages) {
        messenger.showError("Failed to submit this form", "Please try again or contact a staff member to know more about this issue.")
      }
      // Unset isSubmitting flag
      setIsSubmitting(false)
    })
  }

  // RENDER COMPONENTS
  const intro = <Markdown input={props.intro} />

  const form = currentPageFields.map(
    (field, key) => {

      // Get field data
      const fieldData = getFieldData(field.mapField, null)

      // Check field data
      if (!fieldData) {
        if (window.eventops?.showDebug) {
          console.debug("\u2716 Form: Failed to get '" + field.mapField + "' data.\nThis field will be ignored.\nIgnore this error if you're adding this field. This field's data will be added in the next rendering cycle.")
        }
        return null
      }

      // Check if hidden
      if (field.hidden) {
        if (props.isEditing) {
          return <FieldHidden
            key={key}
            label={field.label}
            value={fieldData.value}
          />
        }
        return null
      }

      // Function for setting value
      const setFieldValue = value => {
        // Save changed mapField
        if (!lastChanged !== field.mapField) {
          setLastChanged(field.mapField)
        }
        // Update field in App state
        if (fieldData.value !== value) {
          if (window.eventops?.showDebug) {
            console.debug("\u2714 Form: Changing value of " + field.mapField +
              " (" + JSON.stringify(fieldData.value) + " -> " + JSON.stringify(value) + ").")
          }
          const nextFieldsData = { ...fieldsData }
          nextFieldsData[field.mapField].value = value
          setFieldsData(nextFieldsData)
        }
      }

      // Widget
      if (field.type === "spaces_widget") {
        return (
          <Widget
            key={key}
            field={field}
            fieldData={fieldData}
            setFieldValue={setFieldValue}
            locationField={getFieldData("location")}
            timezoneField={getFieldData("timezone")}
            startDateField={getFieldData("start_date")}
            startTimeField={getFieldData("start_time")}
            endDateField={getFieldData("end_date")}
            endTimeField={getFieldData("end_time")}
            seatingCapacityField={getFieldData("expected_attendance")}
          />
        )
      }

      // Others
      // Check if should render
      if (!fieldData.render) {
        return null
      }

      // Get generic props
      const fieldProps = {
        key: key,
        className: null,
        label: field.label,
        buttonText: field.buttonText,
        required: field.required,
        name: field.mapField,
        value: fieldData.value,
        values: field.values,
        placeholder: field.placeholder,
        valid: fieldData.valid,
        validMessage: field.validation?.validMessage,
        invalidMessage: fieldData.invalidMessage || field.validation?.invalidMessage,
        help: field.help,
        helpAboveInput: field.helpAboveInput,
        onChange: function (event) {
          setFieldValue(event.target.value)
        },
        onBlur: null,
        disabled: null,
        isInLine: field.isInLine,
        isMultiple: field.isMultiple,
        min: fieldData.minValue,
        max: fieldData.maxValue
      }

      // Timezone props
      if (field.mapField === "timezone") {
        // Check values array
        if (!fieldProps.values.length) {
          fieldProps.values = timeZoneValues
        }
      }

      // Render field
      switch (field.type) {
        case "text":
          return <FieldText {...fieldProps} />
        case "textarea":
          return <FieldTextArea {...fieldProps} />
        case "email":
          return <FieldEmail {...fieldProps} />
        case "radio":
          return <FieldRadio {...fieldProps} />
        case "checkbox":
          return <FieldCheckbox {...fieldProps} />
        case "dropdown":
          return <FieldDropdown {...fieldProps} />
        case "dropdown_api":
          return <FieldFetch {...Object.assign(fieldProps, {
            type: "dropdown",
            api: fieldData.api,
            inAdmin: props.isEditing
          })} />
        case "datalist":
          return <FieldDatalist {...fieldProps} />
        case "datalist_api":
          return <FieldFetch {...Object.assign(fieldProps, {
            type: "datalist",
            api: fieldData.api,
            inAdmin: props.isEditing
          })} />
        case "date":
          return <FieldDate {...fieldProps} />
        case "time":
          return <FieldTime {...fieldProps} />
        case "number":
          return <FieldNumber {...fieldProps} />
        case "tel":
          return <FieldTel {...fieldProps} />
        case "markdown":
          return <FieldMarkdown {...Object.assign(fieldProps, { value: field.defaultValue })} />
        case "iframe":
          return <FieldIframe {...Object.assign(fieldProps, {
            url: field.url,
            query: field.query,
            height: field.height,
            fieldsData: fieldsData,
          })} />
        case "file":
          return <FieldFile {...fieldProps} />
        default:
          console.error("Form Field Error: '" + field.type + "' is not a valid field type.\nThis field will be ignored.")
      }
      return null
    }
  )

  const progressBar = isFormValid && pages.length > 1 ? <Progress
    className="mb-3"
    percent={(currentPage + 1) / pages.length * 100}
  //format={percent => `Page ${currentPage + 1} of ${pages.length}`}
  /> : null

  const buttons = isFormValid ? (
    <div className="text-right">
      {currentPage > 0 && (
        <ButtonPrimary
          className="mr-1"
          title="Previous Page"
          text="Previous"
          onClick={prevPage}
          disabled={isSaving || isSubmitting}
        />
      )}
      {currentPage < (pages.length - 1) && (
        <ButtonPrimary
          className={props.form.can_be_saved && props.user ? "mr-1" : ""}
          title="Next Page"
          text="Next"
          onClick={readyForSubmit ? nextPage : () => setShowValidations(true)}
          disabled={(!readyForSubmit && showValidations) || isSaving || isSubmitting}
        />
      )}
      {props.user && (canBeSaved || (props.isEditing && props.form.can_be_saved)) && (
        <ButtonPrimary
          className={currentPage === (pages.length - 1) ? "mr-1" : ""}
          title={"Save this form"}
          text="Save"
          onClick={handleSave}
          disabled={isSaving || isSubmitting || !canBeSaved}
        />
      )}
      {currentPage === (pages.length - 1) && (
        <ButtonSuccess
          title="Submit this form"
          text="Submit"
          onClick={readyForSubmit ? handleSubmit : () => setShowValidations(true)}
          disabled={(!readyForSubmit && showValidations) || isSaving || isSubmitting}
        />
      )}
    </div>
  ) : null

  const closeModal = () => {
    setShowModal(false)
    setCurrentPage(0)
    setShowValidations(false)
    setReadyForSubmit(false)
    window.eventops.reload()
  }

  const modal = showModal ? (
    <Dialog
      visible={true}
      title={props.form.submitted_title || props.form.title}
      buttonClose
      onCancel={closeModal}
      buttonOk={<ButtonPrimary title="Request Another" text="Request Another" onClick={closeModal} />}
      onOk={closeModal}
    >
      <Markdown
        input={props.form.submitted_content || "<center>##### Form Successfully Submitted"}
      />
    </Dialog>
  ) : null

  const LoadSavedModal = showLoadSavedModal ? (
    <Dialog
      visible={true}
      title={props.form.title}
      buttonClose
      buttonCancel={
        <ButtonDanger
          title="Start Over"
          text="Start Over"
          onClick={() => setShowLoadSavedModal(false)}
        />
      }
      onCancel={() => setShowLoadSavedModal(false)}
      buttonOk={
        <ButtonSuccess
          title="Load and Continue"
          text="Load and Continue"
          onClick={() => {
            for (let k in fieldsData) {
              if (k in props.form.saved_data) {
                fieldsData[k].value = props.form.saved_data[k]
              }
            }
            runValidations()
            setShowLoadSavedModal(false)
          }}
        />
      }
      onOk={() => setShowLoadSavedModal(false)}
    >
      <Markdown
        style={{ marginBottom: "-0.5rem" }}
        input={"<center>##### You have a saved version of this form.\n##### What would you like to do?"}
      />
    </Dialog>
  ) : null

  if (isLoading) {
    return (
      <div className="form-loading-indicator" >
        <Spinner />
      </div>
    )
  }

  return (
    <>
      {intro}
      {form}
      {progressBar}
      {buttons}
      {modal}
      {LoadSavedModal}
    </>
  )
}
