/* FIELDS' PARAMETERS
 * {
 *   page: <integer>, // Page in form
 *   type: <string>, // From EventOPS ("text", "textarea", "radio", "checkbox", "dropdown", "file", "date" and "time" (not in EventOPS, but accept also "email", "dropdown_api", "datalist", "datalist_api", "number", "tel" and "spaces_widget")
 *   mapField: <string>, // From EventOPS
 *   label: <string>, // From EventOPS
 *   label2: <string>, // Second label. Only for the Widget
 *   addBtnText: <string>, // Add button title (tooltip). Only for the Widget
 *   delBtnText: <string>, // Remove button title (tooltip). Only for the Widget
 *   noDataText: <string>, // Text to show when no result found. Only for the Widget
 *   help: <string>, // Help text below field
 *   dataValue: <varies>, // From EventOPS
 *   values: [array of <values>], // From EventOPS, but only for radio, checkbox and dropdown
 *   isMultiple: <boolean>, // From EventOPS, but only for dropdown
 *   isInLine: <boolean>,  // From EventOPS, but only for radio and checkbox
 *   placeholder: <string>, // Field's placeholder
 *   required: <boolean>, // From EventOPS
 *   conditions: [], // Field's rendering conditions
 *   validation: {
 *     conditions: [], // Field's validation conditions
 *     validMessage: <string>, // Message if validation result in valid
 *     invalidMessage: <string>, // Message if validation result in invalid
 *   },
 *   hidden:<boolean>, // Field's hidden flag
 * }
 * VALUES' PARAMETERS
 * {
 *   value: <string>,
 *   label: <string>
 *   required: <boolean>, // Only for checkbox
 *   invalidMessage: <string>, // Only for checkbox
 * }
 * CONDITIONS' (RENDERING) PARAMETERS
 * {
 *   "type": <string>, // Conditon type: "and" or "or"
 *   "field": <string>, // mapField value of the field to check
 *   "operator": <string>, // Conditon operator: "==", "!=", ">=", "<=", ">" or "<"
 *   "value": <string>, // Value to compare
 * }
 * VALIDATION CONDITIONS' PARAMETERS
 * {
 *   "type": <string>, // Conditon type: "and" or "or"
 *   "field": <string>or<boolean>, // mapField value of the field to check. If false (boolean), will use self value
 *   "operator": <string>, // Conditon operator: "==", "!=", ">=", "<=", ">" or "<"
 *   "checkField": <string>or<boolean>, // mapField value of the field to be compared. If false (boolean), will use a given value in checkValue
 *   "checkValue": <string>, // Value to compare if {checkField: false}
 *   "invalidMessage": <string>, // Message if validation result in invalid
 * }
 */
import moment from 'moment-timezone'
import { copyDeep } from 'libs/utils'


// FORM BASES
export const baseFieldsOptions = [
  { value: "text", label: "Text" },
  { value: "textarea", label: "Textarea" },
  { value: "email", label: "Email" },
  { value: "radio", label: "Radio" },
  { value: "checkbox", label: "Checkbox" },
  { value: "dropdown", label: "Dropdown" },
  { value: "dropdown_api", label: "Dropdown from Database" },
  { value: "datalist", label: "Datalist" },
  { value: "datalist_api", label: "Datalist from Database" },
  { value: "date", label: "Date" },
  { value: "time", label: "Time" },
  { value: "number", label: "Number" },
  { value: "tel", label: "Telephone" },
  { value: "file", label: "File" },
  { value: "markdown", label: "Markdown" },
  { value: "iframe", label: "iFrame" },
  { value: "spaces_widget", label: "Spaces Widget" },
]

export const baseFields = {
  text: {
    type: "text",
    mapField: "text",
    label: "Text:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    placeholder: "",
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false
  },
  textarea: {
    type: "textarea",
    mapField: "textarea",
    label: "Text:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    placeholder: "",
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false
  },
  email: {
    type: "email",
    mapField: "email",
    label: "Email:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    placeholder: "",
    required: false,
    conditions: [],
    validation: {
      conditions: [{
        type: "and",
        field: "",
        operator: "regex",
        checkField: "",
        checkValue: "^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$",
        invalidMessage: "This doesn't looks like a valid email."
      }],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
  },
  radio: {
    type: "radio",
    mapField: "radio",
    label: "Select:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    values: [{
      value: "value_1",
      label: "Value 1"
    }],
    isInLine: false,
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
  },
  checkbox: {
    type: "checkbox",
    mapField: "checkbox",
    label: "Select:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    values: [{
      value: "value_1",
      label: "Value 1",
      required: false,
      invalidMessage: ""
    }],
    isInLine: false,
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
  },
  dropdown: {
    type: "dropdown",
    mapField: "dropdown",
    label: "Select:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    values: [{
      value: "value_1",
      label: "Value 1"
    }],
    isMultiple: false,
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
  },
  dropdown_api: {
    type: "dropdown_api",
    mapField: "dropdown",
    label: "Select:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    isMultiple: false,
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
    api: {
      method: "GET",
      url: "",
      authorization: "",
      request: null,
      response: {
        array: [],
        label: [],
        value: []
      }
    }
  },
  datalist: {
    type: "datalist",
    mapField: "datalist",
    label: "Select:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    values: [{
      value: "value_1",
      label: "Value 1"
    }],
    placeholder: "",
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
  },
  datalist_api: {
    type: "datalist_api",
    mapField: "datalist",
    label: "Select:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    placeholder: "",
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
    api: {
      method: "GET",
      url: "",
      authorization: "",
      request: null,
      response: {
        array: [],
        label: [],
        value: []
      }
    }
  },
  date: {
    type: "date",
    mapField: "date",
    label: "Date:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    minValue: {
      field: "",
      formula: ""
    },
    maxValue: {
      field: "",
      formula: ""
    },
    placeholder: "",
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
  },
  time: {
    type: "time",
    mapField: "time",
    label: "Time:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    minValue: {
      field: "",
      formula: ""
    },
    maxValue: {
      field: "",
      formula: ""
    },
    placeholder: "",
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
  },
  number: {
    type: "number",
    mapField: "number",
    label: "",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    minValue: {
      field: "",
      formula: ""
    },
    maxValue: {
      field: "",
      formula: ""
    },
    placeholder: "",
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
  },
  tel: {
    type: "tel",
    mapField: "tel",
    label: "Phone:",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    valueConditions: [],
    placeholder: "",
    required: false,
    conditions: [],
    validation: {
      conditions: [{
        type: "and",
        field: "",
        operator: "regex",
        checkField: "",
        checkValue: "(^(\\+\\d{1,3})( ?\\(\\d{2,3}\\))?|^\\(\\d{2,3}\\)|^\\d+)( ?\\d+([ .-]\\d+)*)*$",
        invalidMessage: "This doesn't looks like a valid phone."
      }],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false,
  },
  file: {
    type: "file",
    mapField: "file",
    label: "File:",
    buttonText: "",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    isMultiple: false,
    placeholder: "",
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false
  },
  markdown: {
    type: "markdown",
    mapField: "markdown",
    defaultValue: "<center>##### Markdown",
    conditions: [],
    hidden: false
  },
  iframe: {
    type: "iframe",
    mapField: "iframe",
    label: "",
    help: "",
    helpAboveInput: false,
    url: "",
    query: [],
    height: "500",
    conditions: [],
    hidden: false
  },
  spaces_widget: {
    type: "spaces_widget",
    mapField: "spaces_widget",
    label: "Choose your space(s):",
    label2: "Review your space(s):",
    help: "",
    helpAboveInput: false,
    defaultValue: "",
    addBtnText: "Add Space",
    delBtnText: "Remove Space",
    noDataText: "No spaces found",
    required: false,
    conditions: [],
    validation: {
      conditions: [],
      validMessage: "",
      invalidMessage: ""
    },
    hidden: false
  }
}

export const baseQueryParam = {
  param: "",
  field: "",
}

export const baseValue = {
  value: "value_1",
  label: "Value 1"
}

export const baseCheckboxValue = {
  value: "value_1",
  label: "Value 1",
  required: false,
  invalidMessage: ""
}

export const baseCondition = {
  type: "and",
  field: "",
  operator: "==",
  value: ""
}

export const baseNestedCondition = {
  type: "and",
  conditions: [baseCondition]
}

export const baseValidationCondition = {
  type: "and",
  field: "",
  operator: "==",
  checkField: "",
  checkValue: "",
  invalidMessage: ""
}

export const baseNestedValidationCondition = {
  type: "and",
  conditions: [baseValidationCondition]
}

export const baseValueCondition = {
  value: "",
  conditions: [baseCondition]
}

// CREATING FORMS ELEMENTS
export const newQueryParam = (queryParamData = null) => {
  let queryParam = copyDeep(baseQueryParam)
  if (!queryParamData) {
    return queryParam
  }
  for (let k in queryParamData) {
    if (k in queryParam) {
      queryParam[k] = queryParamData[k]
    }
  }
  return queryParam
}

export const newValue = (valueData = null) => {
  let value = copyDeep(baseValue)
  if (!valueData) {
    return value
  }
  for (let k in valueData) {
    if (k in value) {
      value[k] = valueData[k]
    }
  }
  return value
}

export const newCheckboxValue = (valueData = null) => {
  let value = copyDeep(baseCheckboxValue)
  if (!valueData) {
    return value
  }
  for (let k in valueData) {
    if (k in value) {
      value[k] = valueData[k]
    }
  }
  return value
}

export const newField = (fieldData = null) => {
  const type = fieldData.type ? fieldData.type : "text"
  let field = copyDeep(baseFields[type])
  if (!fieldData) {
    return field
  }
  for (let k in fieldData) {
    if (k in field) {
      if (k === "values") {
        if (type === "checkbox") {
          field[k] = fieldData[k].map(valueData => newCheckboxValue(valueData))
        } else {
          field[k] = fieldData[k].map(valueData => newValue(valueData))
        }
      }
      else {
        field[k] = fieldData[k]
      }
    }
  }
  return field
}

export const newCondition = mapField => {
  const condition = copyDeep(baseCondition)
  condition.field = mapField
  return condition
}

export const newNestedCondition = mapField => {
  const nestedCondition = copyDeep(baseNestedCondition)
  nestedCondition.conditions[0].field = mapField
  return nestedCondition
}

export const newValidationCondition = mapField => {
  const condition = copyDeep(baseValidationCondition)
  condition.field = mapField
  return condition
}

export const newNestedValidationCondition = mapField => {
  const nestedCondition = copyDeep(baseNestedValidationCondition)
  nestedCondition.conditions[0].field = mapField
  return nestedCondition
}

export const newValueCondition = mapField => {
  const valueCondition = copyDeep(baseValueCondition)
  valueCondition.conditions[0].field = mapField
  return valueCondition
}

// TIMEZONES
const getTimezoneValue = (timezone) => {
  const timezoneOffset = moment.tz(timezone).utcOffset()
  return {
    label: `${timezone} GMT${timezoneOffset >= 0 ? "+" : ""}${(timezoneOffset / 60).toFixed(2)}`,
    value: timezone
}
}
export const timeZoneValues = [
  getTimezoneValue('Africa/Abidjan'),
  getTimezoneValue('Africa/Accra'),
  getTimezoneValue('Africa/Addis_Ababa'),
  getTimezoneValue('Africa/Algiers'),
  getTimezoneValue('Africa/Asmara'),
  getTimezoneValue('Africa/Bamako'),
  getTimezoneValue('Africa/Bangui'),
  getTimezoneValue('Africa/Banjul'),
  getTimezoneValue('Africa/Bissau'),
  getTimezoneValue('Africa/Blantyre'),
  getTimezoneValue('Africa/Brazzaville'),
  getTimezoneValue('Africa/Bujumbura'),
  getTimezoneValue('Africa/Cairo'),
  getTimezoneValue('Africa/Casablanca'),
  getTimezoneValue('Africa/Ceuta'),
  getTimezoneValue('Africa/Conakry'),
  getTimezoneValue('Africa/Dakar'),
  getTimezoneValue('Africa/Dar_es_Salaam'),
  getTimezoneValue('Africa/Djibouti'),
  getTimezoneValue('Africa/Douala'),
  getTimezoneValue('Africa/El_Aaiun'),
  getTimezoneValue('Africa/Freetown'),
  getTimezoneValue('Africa/Gaborone'),
  getTimezoneValue('Africa/Harare'),
  getTimezoneValue('Africa/Johannesburg'),
  getTimezoneValue('Africa/Juba'),
  getTimezoneValue('Africa/Kampala'),
  getTimezoneValue('Africa/Khartoum'),
  getTimezoneValue('Africa/Kigali'),
  getTimezoneValue('Africa/Kinshasa'),
  getTimezoneValue('Africa/Lagos'),
  getTimezoneValue('Africa/Libreville'),
  getTimezoneValue('Africa/Lome'),
  getTimezoneValue('Africa/Luanda'),
  getTimezoneValue('Africa/Lubumbashi'),
  getTimezoneValue('Africa/Lusaka'),
  getTimezoneValue('Africa/Malabo'),
  getTimezoneValue('Africa/Maputo'),
  getTimezoneValue('Africa/Maseru'),
  getTimezoneValue('Africa/Mbabane'),
  getTimezoneValue('Africa/Mogadishu'),
  getTimezoneValue('Africa/Monrovia'),
  getTimezoneValue('Africa/Nairobi'),
  getTimezoneValue('Africa/Ndjamena'),
  getTimezoneValue('Africa/Niamey'),
  getTimezoneValue('Africa/Nouakchott'),
  getTimezoneValue('Africa/Ouagadougou'),
  getTimezoneValue('Africa/Porto-Novo'),
  getTimezoneValue('Africa/Sao_Tome'),
  getTimezoneValue('Africa/Tripoli'),
  getTimezoneValue('Africa/Tunis'),
  getTimezoneValue('Africa/Windhoek'),
  getTimezoneValue('America/Adak'),
  getTimezoneValue('America/Anchorage'),
  getTimezoneValue('America/Anguilla'),
  getTimezoneValue('America/Antigua'),
  getTimezoneValue('America/Araguaina'),
  getTimezoneValue('America/Argentina/Buenos_Aires'),
  getTimezoneValue('America/Argentina/Catamarca'),
  getTimezoneValue('America/Argentina/Cordoba'),
  getTimezoneValue('America/Argentina/Jujuy'),
  getTimezoneValue('America/Argentina/La_Rioja'),
  getTimezoneValue('America/Argentina/Mendoza'),
  getTimezoneValue('America/Argentina/Rio_Gallegos'),
  getTimezoneValue('America/Argentina/Salta'),
  getTimezoneValue('America/Argentina/San_Juan'),
  getTimezoneValue('America/Argentina/San_Luis'),
  getTimezoneValue('America/Argentina/Tucuman'),
  getTimezoneValue('America/Argentina/Ushuaia'),
  getTimezoneValue('America/Aruba'),
  getTimezoneValue('America/Asuncion'),
  getTimezoneValue('America/Atikokan'),
  getTimezoneValue('America/Bahia'),
  getTimezoneValue('America/Bahia_Banderas'),
  getTimezoneValue('America/Barbados'),
  getTimezoneValue('America/Belem'),
  getTimezoneValue('America/Belize'),
  getTimezoneValue('America/Blanc-Sablon'),
  getTimezoneValue('America/Boa_Vista'),
  getTimezoneValue('America/Bogota'),
  getTimezoneValue('America/Boise'),
  getTimezoneValue('America/Cambridge_Bay'),
  getTimezoneValue('America/Campo_Grande'),
  getTimezoneValue('America/Cancun'),
  getTimezoneValue('America/Caracas'),
  getTimezoneValue('America/Cayenne'),
  getTimezoneValue('America/Cayman'),
  getTimezoneValue('America/Chicago'),
  getTimezoneValue('America/Chihuahua'),
  getTimezoneValue('America/Costa_Rica'),
  getTimezoneValue('America/Creston'),
  getTimezoneValue('America/Cuiaba'),
  getTimezoneValue('America/Curacao'),
  getTimezoneValue('America/Danmarkshavn'),
  getTimezoneValue('America/Dawson'),
  getTimezoneValue('America/Dawson_Creek'),
  getTimezoneValue('America/Denver'),
  getTimezoneValue('America/Detroit'),
  getTimezoneValue('America/Dominica'),
  getTimezoneValue('America/Edmonton'),
  getTimezoneValue('America/Eirunepe'),
  getTimezoneValue('America/El_Salvador'),
  getTimezoneValue('America/Fort_Nelson'),
  getTimezoneValue('America/Fortaleza'),
  getTimezoneValue('America/Glace_Bay'),
  getTimezoneValue('America/Goose_Bay'),
  getTimezoneValue('America/Grand_Turk'),
  getTimezoneValue('America/Grenada'),
  getTimezoneValue('America/Guadeloupe'),
  getTimezoneValue('America/Guatemala'),
  getTimezoneValue('America/Guayaquil'),
  getTimezoneValue('America/Guyana'),
  getTimezoneValue('America/Halifax'),
  getTimezoneValue('America/Havana'),
  getTimezoneValue('America/Hermosillo'),
  getTimezoneValue('America/Indiana/Indianapolis'),
  getTimezoneValue('America/Indiana/Knox'),
  getTimezoneValue('America/Indiana/Marengo'),
  getTimezoneValue('America/Indiana/Petersburg'),
  getTimezoneValue('America/Indiana/Tell_City'),
  getTimezoneValue('America/Indiana/Vevay'),
  getTimezoneValue('America/Indiana/Vincennes'),
  getTimezoneValue('America/Indiana/Winamac'),
  getTimezoneValue('America/Inuvik'),
  getTimezoneValue('America/Iqaluit'),
  getTimezoneValue('America/Jamaica'),
  getTimezoneValue('America/Juneau'),
  getTimezoneValue('America/Kentucky/Louisville'),
  getTimezoneValue('America/Kentucky/Monticello'),
  getTimezoneValue('America/Kralendijk'),
  getTimezoneValue('America/La_Paz'),
  getTimezoneValue('America/Lima'),
  getTimezoneValue('America/Los_Angeles'),
  getTimezoneValue('America/Lower_Princes'),
  getTimezoneValue('America/Maceio'),
  getTimezoneValue('America/Managua'),
  getTimezoneValue('America/Manaus'),
  getTimezoneValue('America/Marigot'),
  getTimezoneValue('America/Martinique'),
  getTimezoneValue('America/Matamoros'),
  getTimezoneValue('America/Mazatlan'),
  getTimezoneValue('America/Menominee'),
  getTimezoneValue('America/Merida'),
  getTimezoneValue('America/Metlakatla'),
  getTimezoneValue('America/Mexico_City'),
  getTimezoneValue('America/Miquelon'),
  getTimezoneValue('America/Moncton'),
  getTimezoneValue('America/Monterrey'),
  getTimezoneValue('America/Montevideo'),
  getTimezoneValue('America/Montserrat'),
  getTimezoneValue('America/Nassau'),
  getTimezoneValue('America/New_York'),
  getTimezoneValue('America/Nipigon'),
  getTimezoneValue('America/Nome'),
  getTimezoneValue('America/Noronha'),
  getTimezoneValue('America/North_Dakota/Beulah'),
  getTimezoneValue('America/North_Dakota/Center'),
  getTimezoneValue('America/North_Dakota/New_Salem'),
  getTimezoneValue('America/Nuuk'),
  getTimezoneValue('America/Ojinaga'),
  getTimezoneValue('America/Panama'),
  getTimezoneValue('America/Pangnirtung'),
  getTimezoneValue('America/Paramaribo'),
  getTimezoneValue('America/Phoenix'),
  getTimezoneValue('America/Port_of_Spain'),
  getTimezoneValue('America/Port-au-Prince'),
  getTimezoneValue('America/Porto_Velho'),
  getTimezoneValue('America/Puerto_Rico'),
  getTimezoneValue('America/Punta_Arenas'),
  getTimezoneValue('America/Rainy_River'),
  getTimezoneValue('America/Rankin_Inlet'),
  getTimezoneValue('America/Recife'),
  getTimezoneValue('America/Regina'),
  getTimezoneValue('America/Resolute'),
  getTimezoneValue('America/Rio_Branco'),
  getTimezoneValue('America/Santarem'),
  getTimezoneValue('America/Santiago'),
  getTimezoneValue('America/Santo_Domingo'),
  getTimezoneValue('America/Sao_Paulo'),
  getTimezoneValue('America/Scoresbysund'),
  getTimezoneValue('America/Sitka'),
  getTimezoneValue('America/St_Barthelemy'),
  getTimezoneValue('America/St_Johns'),
  getTimezoneValue('America/St_Kitts'),
  getTimezoneValue('America/St_Lucia'),
  getTimezoneValue('America/St_Thomas'),
  getTimezoneValue('America/St_Vincent'),
  getTimezoneValue('America/Swift_Current'),
  getTimezoneValue('America/Tegucigalpa'),
  getTimezoneValue('America/Thule'),
  getTimezoneValue('America/Thunder_Bay'),
  getTimezoneValue('America/Tijuana'),
  getTimezoneValue('America/Toronto'),
  getTimezoneValue('America/Tortola'),
  getTimezoneValue('America/Vancouver'),
  getTimezoneValue('America/Whitehorse'),
  getTimezoneValue('America/Winnipeg'),
  getTimezoneValue('America/Yakutat'),
  getTimezoneValue('America/Yellowknife'),
  getTimezoneValue('Antarctica/Casey'),
  getTimezoneValue('Antarctica/Davis'),
  getTimezoneValue('Antarctica/DumontDUrville'),
  getTimezoneValue('Antarctica/Macquarie'),
  getTimezoneValue('Antarctica/Mawson'),
  getTimezoneValue('Antarctica/McMurdo'),
  getTimezoneValue('Antarctica/Palmer'),
  getTimezoneValue('Antarctica/Rothera'),
  getTimezoneValue('Antarctica/Syowa'),
  getTimezoneValue('Antarctica/Troll'),
  getTimezoneValue('Antarctica/Vostok'),
  getTimezoneValue('Arctic/Longyearbyen'),
  getTimezoneValue('Asia/Aden'),
  getTimezoneValue('Asia/Almaty'),
  getTimezoneValue('Asia/Amman'),
  getTimezoneValue('Asia/Anadyr'),
  getTimezoneValue('Asia/Aqtau'),
  getTimezoneValue('Asia/Aqtobe'),
  getTimezoneValue('Asia/Ashgabat'),
  getTimezoneValue('Asia/Atyrau'),
  getTimezoneValue('Asia/Baghdad'),
  getTimezoneValue('Asia/Bahrain'),
  getTimezoneValue('Asia/Baku'),
  getTimezoneValue('Asia/Bangkok'),
  getTimezoneValue('Asia/Barnaul'),
  getTimezoneValue('Asia/Beirut'),
  getTimezoneValue('Asia/Bishkek'),
  getTimezoneValue('Asia/Brunei'),
  getTimezoneValue('Asia/Chita'),
  getTimezoneValue('Asia/Choibalsan'),
  getTimezoneValue('Asia/Colombo'),
  getTimezoneValue('Asia/Damascus'),
  getTimezoneValue('Asia/Dhaka'),
  getTimezoneValue('Asia/Dili'),
  getTimezoneValue('Asia/Dubai'),
  getTimezoneValue('Asia/Dushanbe'),
  getTimezoneValue('Asia/Famagusta'),
  getTimezoneValue('Asia/Gaza'),
  getTimezoneValue('Asia/Hebron'),
  getTimezoneValue('Asia/Ho_Chi_Minh'),
  getTimezoneValue('Asia/Hong_Kong'),
  getTimezoneValue('Asia/Hovd'),
  getTimezoneValue('Asia/Irkutsk'),
  getTimezoneValue('Asia/Istanbul'),
  getTimezoneValue('Asia/Jakarta'),
  getTimezoneValue('Asia/Jayapura'),
  getTimezoneValue('Asia/Jerusalem'),
  getTimezoneValue('Asia/Kabul'),
  getTimezoneValue('Asia/Kamchatka'),
  getTimezoneValue('Asia/Karachi'),
  getTimezoneValue('Asia/Kathmandu'),
  getTimezoneValue('Asia/Khandyga'),
  getTimezoneValue('Asia/Kolkata'),
  getTimezoneValue('Asia/Krasnoyarsk'),
  getTimezoneValue('Asia/Kuala_Lumpur'),
  getTimezoneValue('Asia/Kuching'),
  getTimezoneValue('Asia/Kuwait'),
  getTimezoneValue('Asia/Macau'),
  getTimezoneValue('Asia/Magadan'),
  getTimezoneValue('Asia/Makassar'),
  getTimezoneValue('Asia/Manila'),
  getTimezoneValue('Asia/Muscat'),
  getTimezoneValue('Asia/Nicosia'),
  getTimezoneValue('Asia/Novokuznetsk'),
  getTimezoneValue('Asia/Novosibirsk'),
  getTimezoneValue('Asia/Omsk'),
  getTimezoneValue('Asia/Oral'),
  getTimezoneValue('Asia/Phnom_Penh'),
  getTimezoneValue('Asia/Pontianak'),
  getTimezoneValue('Asia/Pyongyang'),
  getTimezoneValue('Asia/Qatar'),
  getTimezoneValue('Asia/Qostanay'),
  getTimezoneValue('Asia/Qyzylorda'),
  getTimezoneValue('Asia/Riyadh'),
  getTimezoneValue('Asia/Sakhalin'),
  getTimezoneValue('Asia/Samarkand'),
  getTimezoneValue('Asia/Seoul'),
  getTimezoneValue('Asia/Shanghai'),
  getTimezoneValue('Asia/Singapore'),
  getTimezoneValue('Asia/Srednekolymsk'),
  getTimezoneValue('Asia/Taipei'),
  getTimezoneValue('Asia/Tashkent'),
  getTimezoneValue('Asia/Tbilisi'),
  getTimezoneValue('Asia/Tehran'),
  getTimezoneValue('Asia/Thimphu'),
  getTimezoneValue('Asia/Tokyo'),
  getTimezoneValue('Asia/Tomsk'),
  getTimezoneValue('Asia/Ulaanbaatar'),
  getTimezoneValue('Asia/Urumqi'),
  getTimezoneValue('Asia/Ust-Nera'),
  getTimezoneValue('Asia/Vientiane'),
  getTimezoneValue('Asia/Vladivostok'),
  getTimezoneValue('Asia/Yakutsk'),
  getTimezoneValue('Asia/Yangon'),
  getTimezoneValue('Asia/Yekaterinburg'),
  getTimezoneValue('Asia/Yerevan'),
  getTimezoneValue('Atlantic/Azores'),
  getTimezoneValue('Atlantic/Bermuda'),
  getTimezoneValue('Atlantic/Canary'),
  getTimezoneValue('Atlantic/Cape_Verde'),
  getTimezoneValue('Atlantic/Faroe'),
  getTimezoneValue('Atlantic/Madeira'),
  getTimezoneValue('Atlantic/Reykjavik'),
  getTimezoneValue('Atlantic/South_Georgia'),
  getTimezoneValue('Atlantic/St_Helena'),
  getTimezoneValue('Atlantic/Stanley'),
  getTimezoneValue('Australia/Adelaide'),
  getTimezoneValue('Australia/Brisbane'),
  getTimezoneValue('Australia/Broken_Hill'),
  getTimezoneValue('Australia/Darwin'),
  getTimezoneValue('Australia/Eucla'),
  getTimezoneValue('Australia/Hobart'),
  getTimezoneValue('Australia/Lindeman'),
  getTimezoneValue('Australia/Lord_Howe'),
  getTimezoneValue('Australia/Melbourne'),
  getTimezoneValue('Australia/Perth'),
  getTimezoneValue('Australia/Sydney'),
  getTimezoneValue('Europe/Amsterdam'),
  getTimezoneValue('Europe/Andorra'),
  getTimezoneValue('Europe/Astrakhan'),
  getTimezoneValue('Europe/Athens'),
  getTimezoneValue('Europe/Belgrade'),
  getTimezoneValue('Europe/Berlin'),
  getTimezoneValue('Europe/Bratislava'),
  getTimezoneValue('Europe/Brussels'),
  getTimezoneValue('Europe/Bucharest'),
  getTimezoneValue('Europe/Budapest'),
  getTimezoneValue('Europe/Busingen'),
  getTimezoneValue('Europe/Chisinau'),
  getTimezoneValue('Europe/Copenhagen'),
  getTimezoneValue('Europe/Dublin'),
  getTimezoneValue('Europe/Gibraltar'),
  getTimezoneValue('Europe/Guernsey'),
  getTimezoneValue('Europe/Helsinki'),
  getTimezoneValue('Europe/Isle_of_Man'),
  getTimezoneValue('Europe/Istanbul'),
  getTimezoneValue('Europe/Jersey'),
  getTimezoneValue('Europe/Kaliningrad'),
  getTimezoneValue('Europe/Kiev'),
  getTimezoneValue('Europe/Kirov'),
  getTimezoneValue('Europe/Lisbon'),
  getTimezoneValue('Europe/Ljubljana'),
  getTimezoneValue('Europe/London'),
  getTimezoneValue('Europe/Luxembourg'),
  getTimezoneValue('Europe/Madrid'),
  getTimezoneValue('Europe/Malta'),
  getTimezoneValue('Europe/Mariehamn'),
  getTimezoneValue('Europe/Minsk'),
  getTimezoneValue('Europe/Monaco'),
  getTimezoneValue('Europe/Moscow'),
  getTimezoneValue('Europe/Nicosia'),
  getTimezoneValue('Europe/Oslo'),
  getTimezoneValue('Europe/Paris'),
  getTimezoneValue('Europe/Podgorica'),
  getTimezoneValue('Europe/Prague'),
  getTimezoneValue('Europe/Riga'),
  getTimezoneValue('Europe/Rome'),
  getTimezoneValue('Europe/Samara'),
  getTimezoneValue('Europe/San_Marino'),
  getTimezoneValue('Europe/Sarajevo'),
  getTimezoneValue('Europe/Saratov'),
  getTimezoneValue('Europe/Simferopol'),
  getTimezoneValue('Europe/Skopje'),
  getTimezoneValue('Europe/Sofia'),
  getTimezoneValue('Europe/Stockholm'),
  getTimezoneValue('Europe/Tallinn'),
  getTimezoneValue('Europe/Tirane'),
  getTimezoneValue('Europe/Ulyanovsk'),
  getTimezoneValue('Europe/Uzhgorod'),
  getTimezoneValue('Europe/Vaduz'),
  getTimezoneValue('Europe/Vatican'),
  getTimezoneValue('Europe/Vienna'),
  getTimezoneValue('Europe/Vilnius'),
  getTimezoneValue('Europe/Volgograd'),
  getTimezoneValue('Europe/Warsaw'),
  getTimezoneValue('Europe/Zagreb'),
  getTimezoneValue('Europe/Zaporozhye'),
  getTimezoneValue('Europe/Zurich'),
  getTimezoneValue('Factory'),
  getTimezoneValue('Indian/Antananarivo'),
  getTimezoneValue('Indian/Chagos'),
  getTimezoneValue('Indian/Christmas'),
  getTimezoneValue('Indian/Cocos'),
  getTimezoneValue('Indian/Comoro'),
  getTimezoneValue('Indian/Kerguelen'),
  getTimezoneValue('Indian/Mahe'),
  getTimezoneValue('Indian/Maldives'),
  getTimezoneValue('Indian/Mauritius'),
  getTimezoneValue('Indian/Mayotte'),
  getTimezoneValue('Indian/Reunion'),
  getTimezoneValue('Pacific/Apia'),
  getTimezoneValue('Pacific/Auckland'),
  getTimezoneValue('Pacific/Bougainville'),
  getTimezoneValue('Pacific/Chatham'),
  getTimezoneValue('Pacific/Chuuk'),
  getTimezoneValue('Pacific/Easter'),
  getTimezoneValue('Pacific/Efate'),
  getTimezoneValue('Pacific/Enderbury'),
  getTimezoneValue('Pacific/Fakaofo'),
  getTimezoneValue('Pacific/Fiji'),
  getTimezoneValue('Pacific/Funafuti'),
  getTimezoneValue('Pacific/Galapagos'),
  getTimezoneValue('Pacific/Gambier'),
  getTimezoneValue('Pacific/Guadalcanal'),
  getTimezoneValue('Pacific/Guam'),
  getTimezoneValue('Pacific/Honolulu'),
  getTimezoneValue('Pacific/Kiritimati'),
  getTimezoneValue('Pacific/Kosrae'),
  getTimezoneValue('Pacific/Kwajalein'),
  getTimezoneValue('Pacific/Majuro'),
  getTimezoneValue('Pacific/Marquesas'),
  getTimezoneValue('Pacific/Midway'),
  getTimezoneValue('Pacific/Nauru'),
  getTimezoneValue('Pacific/Niue'),
  getTimezoneValue('Pacific/Norfolk'),
  getTimezoneValue('Pacific/Noumea'),
  getTimezoneValue('Pacific/Pago_Pago'),
  getTimezoneValue('Pacific/Palau'),
  getTimezoneValue('Pacific/Pitcairn'),
  getTimezoneValue('Pacific/Pohnpei'),
  getTimezoneValue('Pacific/Port_Moresby'),
  getTimezoneValue('Pacific/Rarotonga'),
  getTimezoneValue('Pacific/Saipan'),
  getTimezoneValue('Pacific/Tahiti'),
  getTimezoneValue('Pacific/Tarawa'),
  getTimezoneValue('Pacific/Tongatapu'),
  getTimezoneValue('Pacific/Wake'),
  getTimezoneValue('Pacific/Wallis'),
]
