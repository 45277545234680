import React from 'react'
import BaseField from './BaseField'


export default function FieldDate(props) {
  /* Input Date
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * min = <integer> Optional
   * max = <integer> Optional
   */
  const [value, setValue] = React.useState(props.value)
  const [changingValue, setChangingValue] = React.useState(false)

  // VALIDATION
  React.useEffect(() => {
    if (changingValue) {
      // Check if user finished typing year
      if (value.startsWith("0")) {
        if (props.value !== "") {
          props.onChange({ target: { value: "" } })
        }
      }
      // Update value in state
      else if (props.value !== value) {
        props.onChange({ target: { value: value } })
      }
      setChangingValue(false)
    }
    // Check if state value is different of value
    else if (!value.startsWith("0") && value !== props.value) {
      setValue(props.value)
    }
  }, [value, props.value])

  // FUNCTIONS
  const onBlur = event => {
    // Make sure input has the same state value
    if (value !== props.value) {
      event.target.value = props.value
      setValue(event.target.value)
    }
    // Run onBlur, if provided
    if (props.onBlur) {
      props.onBlur(event)
    }
  }

  const onChange = event => {
    setValue(event.target.value)
    setChangingValue(true)
  }

  // RENDER
  return (
    <BaseField
      className={props.className}
      label={props.label}
      input={
        <input
          className={"form-control custom-field-input" + (
            props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
          )}
          name={props.name}
          type="date"
          placeholder={props.placeholder}
          value={value || ""}
          required={props.required && props.valid === false}
          onChange={onChange}
          onBlur={onBlur}
          disabled={props.disabled}
          min={props.min}
          max={props.max}
        />
      }
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
    />
  )
}
