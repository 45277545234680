import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Components
import { Spinner } from 'components/spinner'
import BaseField from './BaseField'
import { Select } from 'antd'
const { Option } = Select
export default function FieldSearch(props) {
  /*
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * values = [{ label: <string>, value: <string> }] Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * input = <JSX content>
   *  api = {
   *   method = <string> Required
   *   url = <string> Required
   *   authorization = <string> Optional
   *   request = <Object> Optional
   *   response = {
   *     array = [<string>] Required
   *     label = [<string>] Required
   *     value = [<string>] Required
   *   }
   *  }
   */

  const [values, setValues] = useState([])
  const [options, setOptions] = useState()
  const [search, setSearch] = useState('')
  const [apiQuery, setApiQuery] = useState(search ? `&search=${search}` : '')
  const [isLoading, setIsLoading] = useState(true)

  const onSearch = (val) => {
    setSearch(val)
  }

  const onChange = (val) => {
    props.onChange({ target: { value: val } })
  }

  // INITIALIZATION
  const getValues = () => {
    // Cancel listener
    let wasCancelled = false
    const returningObj = {
      cancel: function () {
        wasCancelled = true
      }
    }
    // Set is loading flag
    setIsLoading(true)
    // Request values locally if user token is not required
    if (!props.api.authorization || !props.api.authorization.includes('{user_token}')) {
      fetch(props.api.url.replace('{tenant_url}', window.eventops.tenantUrl) + `?page_size=50${apiQuery}`, {
        method: props.api.method,
        mode: 'cors',
        headers: props.api.authorization
          ? {
              'Content-Type': 'application/json',
              Authorization: props.api.authorization.replace('{company_token}', window.eventops.tenantToken)
            }
          : { 'Content-Type': 'application/json' },
        body: props.api.method === 'GET' ? undefined : JSON.stringify(props.api.request)
      })
        .then(async (response) => {
          // Check if was not cancelled
          if (wasCancelled) {
            return returningObj
          }
          // If request failed:
          if (response.status !== 200) {
            console.error('Failed to fetch data for field. Server response: ' + response.statusText)
            setValues([])
            setIsLoading(false)
            return returningObj
          }
          // If not, get response payload
          const responseJson = await response.json()
          // Get list
          const objects = props.api.response.array.reduce((objects, index) => objects[index], responseJson)
          // Check list
          if (!Array.isArray(objects)) {
            console.error(
              'Failed to fetch data for field. Could not get an array from response: ' + JSON.stringify(objects)
            )
            setValues([])
            setIsLoading(false)
            return returningObj
          }
          // Get values
          const values = objects.map((object) => ({
            label: props.api.response.label.reduce((label, index) => label[index], object),
            value: props.api.response.value.reduce((value, index) => value[index], object)
          }))
          // Check values
          if (values[0] && (values[0].label instanceof Object || values[0].value instanceof Object)) {
            console.error(
              'Failed to fetch data for field. Could not get a valid value from response: ' + JSON.stringify(values[0])
            )
            setValues([])
            setIsLoading(false)
            return returningObj
          }
          // Check if was not cancelled
          if (wasCancelled) {
            return returningObj
          }
          // Save results
          setValues(values)
          setIsLoading(false)

          const items = values.map((data, key) => (
            <Option value={data.value} label={data.label} key={key}>
              {data.label}
            </Option>
          ))
          setOptions(items)
        })
        .catch((err) => console.error(err))
    }
    // Returning canceling function
    return returningObj
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setApiQuery(search ? `&search=${search}` : '')
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [search])

  useEffect(() => {
    if (props.api && props.api.url && props.api.response) {
      if (/^(https:\/\/|api\/)[\S]+$/.test(props.api.url)) {
        const getValuesRequest = getValues()
        return getValuesRequest.cancel
      } else {
        console.error("Failed to fetch data for field. '" + props.api.url + "' is not a valid URL.")
        setValues([])
        setIsLoading(false)
      }
    } else {
      console.error('Failed to fetch data for field. Could not find necessary API data.')
      setValues([])
      setIsLoading(false)
    }
  }, [JSON.stringify(props.api), apiQuery])

  return (
    <BaseField
      className={props.className}
      label={props.label}
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
      input={
        <Select
          disabled={props.disabled && true}
          suffixIcon={isLoading ? <Spinner /> : undefined}
          notFoundContent={isLoading ? "Loading..." : undefined}
          placeholder={props.placeholder || 'Type to search...'}
          className="w-100"
          showSearch
          allowClear={props.allowClear && true}
          onChange={onChange}
          filterOption={false}
          onSearch={onSearch}
          onBlur={props.onBlur}
          value={props.value}
          onClear={() => setApiQuery('')}
        >
          {options}
        </Select>
      }
    />
  )
}

FieldSearch.propTypes = {
  className: PropTypes.string,
  allowClear: PropTypes.bool,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired
    })
  ),
  placeholder: PropTypes.string,
  valid: PropTypes.bool,
  validMessage: PropTypes.string,
  invalidMessage: PropTypes.string,
  help: PropTypes.string,
  helpAboveInput: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  input: PropTypes.element,
  api: PropTypes.shape({
    method: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    authorization: PropTypes.string,
    request: PropTypes.object,
    response: PropTypes.shape({
      array: PropTypes.arrayOf(PropTypes.string).isRequired,
      label: PropTypes.arrayOf(PropTypes.string).isRequired,
      value: PropTypes.arrayOf(PropTypes.string).isRequired
    })
  }).isRequired
}
