import React from 'react'
import BaseField from './BaseField'
import FieldDropdown from './FieldDropdown'
import FieldDatalist from './FieldDatalist'


export default function FieldHidden(props) {
  /* Input Hidden
   * Expected Props:
   * label = <string> Required
   * value = <string> Required
   */

  // RENDER
  return (
    <BaseField
      className="field-hidden" //border border-secondary"
      label={props.label}
      input={
        <input
          className="form-control custom-field-input"
          type="text"
          value={props.value}
          disabled={true}
        />
      }
      help="This is a hidden field and will not be shown on the form - it is shown here for debug and planning purposes only."
    />
  )
}
