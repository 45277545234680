import React from 'react'
import BaseField from './BaseField'


export default function FieldNumber(props) {
  /* Input Number
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * step = <string> Optional
   * min = <integer> Optional
   * max = <integer> Optional
   */

  // FUNCTIONS
  const onChange = event => {
    // Process changes ONLY if valid
    if (event.target.validity.valid) {
      props.onChange(event)
    }
  }

  const onInput = event => {
    // If NOT valid
    if (!event.target.validity.valid) {
      // If bad value is saved to state or trying to empty a required field
      if (event.target.value === props.value || event.target.validity.valueMissing) {
        // Set value as empty string
        event.target.value = ""
        props.onChange(event)
      }
    }
  }

  // RENDER
  return (
    <BaseField
      className={props.className}
      label={props.label}
      input={
        <input
          className={"form-control custom-field-input" + (
            props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
          )}
          name={props.name}
          type="number"
          placeholder={props.placeholder}
          value={props.value}
          required={props.required && props.valid === false}
          onChange={onChange}
          onInput={onInput}
          onBlur={props.onBlur}
          disabled={props.disabled}
          readOnly={props.readOnly}
          step={props.step}
          min={props.min}
          max={props.max}
        />
      }
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
    />
  )
}
