import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSave } from "@fortawesome/pro-duotone-svg-icons"
import PropTypes from 'prop-types'
import BaseButton from './BaseButton'


export default function ButtonSave({
  className,
  disabled,
  loading,
  onClick,
  size,
  title,
}) {

  return (
    <BaseButton
      className={"save-button" + (className ? " " + className : "")}
      icon={
        <FontAwesomeIcon className="w-100 h-100" icon={faSave} />
      }
      type="text"
      size={size}
      title={title || "Save"}
      disabled={disabled}
      loading={loading}
      onClick={onClick}
    />
  )
}

ButtonSave.propTypes = {
  title: PropTypes.string,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf([
    'large',
    'middle',
    'small'
  ]),
  loading: PropTypes.bool,
  onClick: PropTypes.func,
}
