import React from 'react'
import BaseField from './BaseField'


export default function FieldEmail(props) {
  /* Input Email
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   */

  // RENDER
  return (
    <BaseField
      className={props.className}
      label={props.label}
      input={
        <input
          className={"form-control custom-field-input" + (
            props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
          )}
          name={props.name}
          type="email"
          placeholder={props.placeholder}
          value={props.value || ""}
          required={props.required && props.valid === false}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
        />
      }
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
    />
  )
}
