import './index.scss'
export * from './dataCheckers'
export * from './format'
export * from './navigations'
export * from './objectHandlers'
export * from './renders'
export * from './sortValues'


export function getRandomColor() {
  return (
    '#' +
    ('000000' + Math.floor(Math.random() * 0xffffff).toString(16)).substr(-6)
  )
}

export function getUniqueName(name, listOfNames) {
  // If name not on list
  if (!listOfNames.includes(name)) {
    return name
  }

  // Get an unique name
  const cleanedName = name.replace(/_[0-9]+$/, '')
  let nameCopies = 1
  while (listOfNames.includes(cleanedName + '_' + nameCopies)) {
    nameCopies += 1
  }
  return cleanedName + '_' + nameCopies
}

// Rounds and trims number to the digits place
export function roundAndTruncate(number, digits) {
  if (typeof number !== 'number' && typeof digits !== 'number') {
    throw new Error('Parameters must be numbers')
  }
  return parseFloat(number.toFixed(digits))
}
