import './Footer.scss'
import React from 'react'


export default function Footer(props) {
  return (
    <footer className="app-layout-footer">
      <p className="footer-content">
        © {new Date().getFullYear()} Elevar Technologies, Inc.
      </p>
    </footer>
  )
}
