import React from 'react'
import { Route, Switch } from 'react-router-dom'
import Form from './form'
import NotFound from 'apps/notfound'


export default function Forms(props) {

  return (
    <Switch>
      <Route exact path="/:type/:form">
        <Form {...props} />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  )
}
