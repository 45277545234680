import React from 'react'
import BaseField from './BaseField'
import { copyDeep, isEqual, sortArrayByKey } from 'libs/utils'


export default function FieldDropdown(props) {
  /* Input Dropdown
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * values = [{ label: <string>, value: <string> }] Required
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * showEmpty = <boolean> Optional (default true)
   * reorder = <boolean> Optional (default true)
   */
  const [options, setOptions] = React.useState(null)
  const [values, setValues] = React.useState(null)
  const [prevValues, setPrevValues] = React.useState()

  // INITIALIZATION
  React.useEffect(() => {
    if (!isEqual(prevValues, props.values)) {
      // Get options
      const newOptions = props.values?.length > 0 ?
        (props.showEmpty === false ? [] : [{ label: "---- Select an option ----", value: "" }]).concat(
          props.reorder === false ? props.values : sortArrayByKey(props.values, "label")
        )
        :
        [{ label: "No Data", value: "" }]
      // Save options
      setOptions(newOptions.map((option, key) =>
        <option key={key} value={"" + option.value}>{"" + option.label}</option>
      ))
      // Get values
      const newValues = newOptions.map(v => "" + v.value)
      // Save values
      setValues(newValues)
      // Check value
      if (!newValues.includes(props.value)) {
        props.onChange({ target: { value: (props.showEmpty === false && newValues[0]) || "" } })
      }
      setPrevValues(copyDeep(props.values))
    }
  }, [props.values])

  // VALIDATION
  // Check if state value is a valid option in values
  React.useEffect(() => {
    if (values !== null && props.value !== "" && !values.includes(props.value)) {
      props.onChange({ target: { value: (props.showEmpty === false && values[0]) || "" } })
    }
  }, [props.value])

  // RENDER
  return (
    <BaseField
      className={props.className}
      label={props.label}
      input={
        <select
          className={"form-control custom-field-input" + (
            props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
          )}
          name={props.name}
          type="dropdown"
          value={props.value || ""}
          required={props.required && props.valid === false}
          onChange={props.onChange}
          onBlur={props.onBlur}
          disabled={props.disabled}
        >
          {options}
        </select>
      }
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
    />
  )
}
