export function isEqual(obj1, obj2) {
  // Check if objects are are of the same type
  if (obj1?.__proto__ !== obj2?.__proto__) {
    return false
  }
  // If objects are an array
  if (Array.isArray(obj1)) {
    // Check lenght
    if (obj1.length !== obj2.length) {
      return false
    }
    // Check content
    for (let i in obj1) {
      if (!isEqual(obj1[i], obj2[i])) {
        return false
      }
    }
    // If all pass
    return true
  }
  // If objects are an Object
  if (obj1 instanceof Object) {
    // Check keys
    if (Object.keys(obj1).toString() !== Object.keys(obj2).toString()) {
      return false
    }
    // Check content
    for (let i in obj1) {
      if (!isEqual(obj1[i], obj2[i])) {
        return false
      }
    }
    // If all pass
    return true
  }
  // If objects are anything else
  return obj1 === obj2
}

export function isEmpty(obj) {
  for (let key in obj) {
    return false
  }
  return true
}

export function isDict(obj) {
  return obj?.__proto__ === {}.__proto__
}

export function isString(value) {
  return typeof value === 'string' || value instanceof String
}

const colorRegex = new RegExp("^#[0-9A-f]{6}$")
export function isColorLight(color) {
  if (!colorRegex.test(color)) return
  const convertRgb = (c) => {
    // sRGB to linear RGB
    c = c / 255.0
    return c <= 0.03928 ? c / 12.92 : Math.pow((c + 0.055) / 1.055, 2.4)
  }
  const r = convertRgb(parseInt(color.substr(1, 2), 16))
  const g = convertRgb(parseInt(color.substr(3, 2), 16))
  const b = convertRgb(parseInt(color.substr(5, 2), 16))
  const l = 0.2126 * r + 0.7152 * g + 0.0722 * b // 709 luma coefficients
  return l > 0.3 // W3C value was looking too low -> 0.1791 // From W3C: (l + 0.05) / (0.0(or lBlack) + 0.05) > (1.0(or lWhite) + 0.05) / (l + 0.05)
}

// Email format from: https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address
const emailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$")
export function isValidEmail(email) {
  return emailRegex.test(email)
}
