import React from 'react'
import BaseField from './BaseField'


export default function FieldText(props) {
  /* Input Text
   * Expected Props:
   * className = <string> Optional
   * label = <string> Required
   * required = <boolean> Optional
   * name = <string> Required
   * value = <string> Required
   * placeholder = <string> Optional
   * valid = <boolean> Optional
   * validMessage = <string> Optional
   * invalidMessage = <string> Optional
   * help = <string> Optional
   * helpAboveInput = <boolean> Optional
   * onChange = <function()> Required
   * onBlur = <function()> Optional
   * disabled = <boolean> Optional
   * readOnly = <boolean> Optional
   * prepend = <string> Optional
   * append = <string> Optional
   * maxLength = <integer> Optional
   */

  // RENDER
  const input = (
    <input
      className={
        "form-control custom-field-input" + (
          props.valid === true ? " is-valid" : props.valid === false ? " is-invalid" : ""
        )
      }
      name={props.name}
      type="text"
      placeholder={props.placeholder}
      value={props.value || ""}
      required={props.required && props.valid === false}
      onChange={props.onChange}
      onBlur={props.onBlur}
      disabled={props.disabled}
      readOnly={props.readOnly}
      maxLength={props.maxLength === undefined ? 150 : props.maxLength}
    />
  )

  return (
    <BaseField
      className={props.className}
      label={props.label}
      input={
        props.prepend || props.append ? (
          <div className="input-group">
            {props.prepend ? (
              <div className="input-group-prepend">
                <span className="input-group-text">{props.prepend}</span>
              </div>
            ) : null}
            {input}
            {props.append ? (
              <div className="input-group-append">
                <span className="input-group-text">{props.append}</span>
              </div>
            ) : null}
          </div>
        ) : input
      }
      required={props.required}
      valid={props.valid}
      validMessage={props.validMessage}
      invalidMessage={props.invalidMessage}
      help={props.help}
      helpAboveInput={props.helpAboveInput}
    />
  )
}
